<template>
  <d-table
      :show-pagination="false"
      :tablefields="tableFields"
      :items="items"
      :is-busy="false"
      :totalRows="3"
  >

  </d-table>
</template>
<script>
export default {
  data: () => ({
    items: []
  }),
  props: {
    emailCampaign: {
      type: Object,
      default: () => {
      }
    },
    selectedCustomers: {
      type: Array,
      default: () => []
    },
    selectedSubscriptions: {
      type: Array,
      default: () => []
    },
    selectedActivities: {
      type: Array,
      default: () => []
    },
    selectedGroups: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    tableFields() {
      return [
        {
          key: "iconLabel",
          iconClass: "fa fa-cog text-blue-darken",
          labelClass: "table-title-bold",
          label: this.$t("views.marketing.report.resume-content.informations-table.title"),
          sortable: false
        },
        {
          key: "campaignValue",
          label: "",
          class: 'text-left',
          sortable: false
        },
      ];
    },
  },
  created() {
    if (this.$route.name === 'campaign_sms_report' || this.$route.name === 'campaign_push_report') {
      this.items = [
        {
          iconLabel: this.$t("views.marketing.report.resume-content.informations-table.campaign-name"),
          campaignValue: this.emailCampaign.name
        },
        {
          iconLabel: this.$t("views.marketing.report.resume-content.informations-table.campaign-sending-date"),
          campaignValue: this.emailCampaign.programmingDate ? this.$moment(this.emailCampaign.programmingDate).format('DD/MM/YYYY - HH:mm') : this.$t('general.actions.immediat')
        }
      ];
    } else {
      this.items = [
        {
          iconLabel: this.$t("views.marketing.report.resume-content.informations-table.campaign-name"),
          campaignValue: this.emailCampaign.name
        },
        {
          iconLabel: this.$t("views.marketing.report.resume-content.informations-table.campaign-subject"),
          campaignValue: this.emailCampaign.subject
        },
        {
          iconLabel: this.$t("views.marketing.report.resume-content.informations-table.campaign-sending-date"),
          campaignValue: this.emailCampaign.programmingDate ? this.$moment(this.emailCampaign.programmingDate).format('DD/MM/YYYY - HH:mm') : this.$t('general.actions.immediat')
        }
      ];
    }

  }
}
</script>
